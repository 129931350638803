@keyframes fade_right {
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

/* .newsElement.top {
  border-top: 1px solid #f8f8fb;
}

.newsElement::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  border-bottom: solid 1.5px #f8f8fb;
} */
.newsElement.hasLink::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 0%;
  border-bottom: solid 1.5px #29a4e6;
}

.message {
  visibility: hidden;
}

.message.visible {
  visibility: visible;
}

@keyframes border_anim {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.chakra-modal__body a {
  color: #143282;
  text-decoration: underline;
}

@media (min-width: 835px) {
  .newsElement.hasLink:hover {
    cursor: pointer;
  }
  .newsElement.hasLink:hover::before {
    animation: border_anim 0.5s ease forwards;
    width: 100%;
  }

  .chakra-modal__body a:hover {
    cursor: pointer;
  }
}
@media (min-width: 426px) and (max-width: 834px) {
}
@media (max-width: 425px) {
}
