.Header {
  position: relative;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
}

body {
  color: #222;
  font-family:
    Noto Sans JP,
    -apple-system,
    blinkmacsystemfont,
    Segoe UI,
    Hiragino Kaku Gothic ProN,
    BIZ UDPGothic,
    meiryo,
    sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.04em;
  word-break: break-all;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li {
  list-style-type: none; /*リストマーカー無しにする*/
}

.mikawa_face {
  width: 7%;
  transform-origin: center;
}

.mikawa_face.pc.clicked {
  width: 10%;
  animation: guragura 0.7s steps(1) infinite alternate;
}

.mikawa_face.sp.clicked {
  width: 12%;
  animation: guragura_sp 0.7s steps(1) infinite alternate;
}

@keyframes guragura {
  0% {
    transform-origin: 35% 35%;
    transform: rotate(-15deg) translate(-15%, -15%);
  }
  50% {
    transform-origin: 35% 35%;
    transform: rotate(15deg) translate(-15%, -15%);
  }
  100% {
    transform-origin: 35% 35%;
    transform: rotate(-15deg) translate(-15%, -15%);
  }
}
@keyframes guragura_sp {
  0% {
    transform-origin: 50% 50%;
    transform: rotate(-15deg) translate(-10%, -10%);
  }
  50% {
    transform-origin: 50% 50%;
    transform: rotate(15deg) translate(-10%, -10%);
  }
  100% {
    transform-origin: 50% 50%;
    transform: rotate(-15deg) translate(-10%, -10%);
  }
}

.compass {
  transform-origin: center;
}

.compass.pc.clicked {
  animation: guragura_compass 0.7s steps(1) infinite alternate;
}

.compass.sp.clicked {
  animation: guragura_compass 0.7s steps(1) infinite alternate;
}

@keyframes guragura_compass {
  0% {
    transform-origin: 50% 50%;
    transform: rotate(-10deg) translate(2%, -1%);
  }
  50% {
    transform-origin: 50% 50%;
    transform: rotate(10deg) translate(2%, -1%);
  }
  100% {
    transform-origin: 50% 50%;
    transform: rotate(-10deg) translate(2%, -1%);
  }
}

.pos_image {
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in;
  z-index: 9999;
}

.pos_image.clicked {
  visibility: visible;
  opacity: 1;
  transform: translateY(calc(-10%));
}

a {
  transition: 0.2s;
}
.fade_right {
  animation: fade_right 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.cp_box *,
.cp_box *:before,
.cp_box *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.cp_box {
  position: relative;
}
.cp_box label {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 140px;
  cursor: pointer;
  text-align: center;
  background: linear-gradient(
    to bottom,
    rgba(250, 252, 252, 0) 0%,
    rgba(250, 252, 252, 0.95) 90%
  );
}
.cp_box input:checked + label {
  background: inherit;
  transition: 0.3s;
}
.cp_box label:after {
  line-height: 2.5rem;
  font-family: "Noto Serif JP", serif;
  position: absolute;
  z-index: 2;
  bottom: 20px;
  left: 50%;
  width: 12rem;
  content: "▼   他の動画も見る";
  transform: translate(-50%, 0);
  letter-spacing: 0.05em;
  color: #ffffff;
  border-radius: 10px;
  background-color: #183c9f;
}
.cp_box input {
  display: none;
}
.cp_box .cp_container {
  overflow: hidden;
  height: 250px;
  transition: all 0.5s;
  transition: 0.3s;
}
.cp_box input:checked + label {
  display: none;
}
.cp_box input:checked + label:after {
  font-family: FontAwesome;
  content: "▲  閉じる";
}
.cp_box input:checked ~ .cp_container {
  height: auto;
  transition: all 0.5s;
}

@keyframes fade_right {
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@media (min-width: 835px) {
  .mikawa_face {
    cursor: pointer;
  }
}
@media (min-width: 426px) and (max-width: 834px) {
  .cp_box label {
    bottom: -26%;
  }
}
@media (max-width: 425px) {
  .cp_box label {
    top: 57.5%;
  }
}
.fade_in {
  opacity: 0;
  transform: translateY(70px);
  transition:
    transform 1.2s cubic-bezier(0, 0, 0.2, 1),
    opacity 1s cubic-bezier(0, 0, 0.2, 1);
}

.fade_in.active {
  opacity: 1;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.flowing::before {
  animation: flowing-anim 3s linear forwards;
}

@keyframes flowing-anim {
  0% {
    transform: translateX(-20%);
  }
  20% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
