.btn-square-slant {
  border-radius: 10px;
  display: inline-block;
  position: relative;
  padding: 0.5em 3em;
  text-decoration: none;
  background: #183c9f;
  color: #fff;
}

.btn-square-slant:after {
  content: "";
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 1.5px;
  left: 1.5px;
  z-index: -1;
}

.btn-square-slant::before {
  content: "";
  background-color: #143282;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: -2;
}

.btn-square-slant:active {
  -webkit-transform: translate(6px, 6px);
  transform: translate(6px, 6px);
}
.btn-square-slant:active::before {
  display: none;
}

.btn-square-slant:active:after {
  display: none;
}

@keyframes pop {
  0% {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  90% {
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulse {
  50% {
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 1;
  }
  70% {
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes popup {
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (min-width: 835px) {
}
@media (min-width: 426px) and (max-width: 834px) {
}
@media (max-width: 425px) {
}
