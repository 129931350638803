/* 分類リスト */
#classList {
  padding: 10px 10px 0px;
  font-size: 0.9em;
  line-height: 150%;
  position: relative;
}

#dspClassArea {
  max-width: 1000px;
  margin: 0px auto 30px;
}
/* 2016/11/29 アクセシビリティ対応  ul#footerLinkに影響しないようにする dspClassArea追加*/
#classList #dspClassArea ul {
  float: left;
  margin: 0px 9px 10px 0;
  width: 133px;
}

/* 2016/11/29 アクセシビリティ対応  ul#footerLinkに影響しないようにする dspClassArea追加*/
#classList #dspClassArea ul li.topClassName {
  font-weight: bold;
  border-bottom: solid 1px #009143;
  padding-bottom: 0px;
  margin-bottom: 3px;
  display: inline-block;
}
/* 2016/12/13 アクセシビリティ対応 li.topClassNameのfont-weight:boldを消す為にli.underClassName追加*/
.underClassName {
  font-weight: normal;
}
/* 2016/11/29 アクセシビリティ対応  ul#footerLinkに影響しないようにする dspClassArea追加*/
#classList #dspClassArea ul li a {
  text-decoration: none;
}

/* 問合せ先 */
#siteinfo_Area {
  padding: 0px;
  font-size: 1em;
  margin: 0px auto 0px auto;
  width: 100%;
  /* ※背景色は色別CSSで定義 */
}

.footer_info {
  max-width: 1100px;
  margin: 0px auto 0px auto;
}

.toiawase {
  margin: 0px auto 0px auto;
  padding: 25px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1100px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.footerimg {
  margin: 0px auto;
  width: 100%;
  margin: 0px auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  flex-wrap: wrap;
  gap: 15px;
}

.add {
  width: 100%;
  line-height: 1.8em;
}

.add_title {
  font-weight: bold;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 10px 0px;
}

.add p {
  padding: 15px 0px;
}

.text_s {
  font-size: 0.8em;
}

#footerImg2 {
  max-width: 252px;
  float: right;
  margin: 0px 0px 0px 0px;
}

#toiawasetitle {
  padding: 37px 0px 15px;
}

#toiawasetitle span {
  font-weight: bold;
  font-size: 1.5em;
  padding: 20px 0px 10px;
}

.toiawase_right {
  max-width: 332px;
  width: 100%;
}
/* フッターメニュー */
.footer_Menu {
  font-size: 1em;
  max-width: 600px;
  margin: 20px auto 20px;
  text-align: center;
  padding: 5px 0px 5px;
  border: 1px solid #fff;
}

.footer_Menu_item {
  margin: 0px auto 0px;
  text-align: center;
  max-width: 87%;
  font-size: 0.9em;
  width: 100%;
}

.footer_Menu_item ul {
  margin: 0px auto 0px;
  text-align: center;
}

.footer_Menu_item ul li {
  float: left;
  margin: 0px auto 0px;
  padding: 0px 31px;
  border-right: 1px solid #fff;
}

.footer_Menu_item .last {
  border-right: unset;
}

.footerMenu a {
  text-decoration: none;
}
/* 2016/11/29 アクセシビリティ対応 <p><span>→<ul><li>*/
.footerMenu p,
.footerMenu ul {
  max-width: 1100px;
  margin: 0px auto;
}
/* 2016/11/29 アクセシビリティ対応 <p><span>→<ul><li> display: inline-block追加 */
.footerMenu p span,
.footerMenu ul li {
  padding-left: 0px;
  margin: 0px 0px 0px 0px;
  /*background:url("../../images/top1/ya_gray.gif") no-repeat 0px center; */
  display: inline-block;
}

/* フッターリンク */
/* 2016/11/29 アクセシビリティ対応 <div><span>→<div><ul><li>*/
.footerLink {
  max-width: 1100px;
  margin: 0px auto;
  font-weight: bold;
  text-align: center;
  padding-bottom: 10px;
  position: relative;
}

#footerLink img {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

/* 2016/11/29 アクセシビリティ対応 <p><span>→<ul><li>*/
#footerLink span,
#footerLink li {
  padding: 0px 5px;
  display: inline-block;
}

#footerbtn01 ul li {
  float: left;
  margin: 10px 0px 10px 10px;
  padding: 5px 10px;
  border-radius: 5px;
}

.toiawase_left {
  max-width: 740px;
  width: 100%;
}
/* copyright */
#cp {
  margin: 0px auto;
  text-align: center;
  font-size: 0.7em;
  /* ※背景色・文字色は色別CSSで定義 */
  padding: 3px 0px;
}

/* 2016/11/29 アクセシビリティ対応 <p><span>→<ul><li>*/
div#footerBtnAndLogo {
  max-width: 820px;
  margin: 0px auto;
  padding: 3px 0px;
}

/* 2016/11/29 アクセシビリティ対応 　<div><p>→<ul><li> width:1000→800px　float:left;*/
div#footerBtn,
ul#footerBtn {
  max-width: 820px;
  margin: 0px auto;
}

/* 2016/11/29 アクセシビリティ対応 <p><span>→<ul><li> display: inline-block;追加 */
div#footerBtn p,
ul#footerBtn li {
  float: left;
  font-size: 0.9em;
  margin: 0 5px;
  padding: 3px 10px;
}

ul#footerBtn li.cc {
  border-radius: 3px;
  float: left;
  font-size: 0.9em;
  margin: 0 5px;
  padding: 2px 8px;
}

div#footertextlink,
ul#footertextlink {
  margin: 0px auto 0px;
  color: #333333;
  text-align: center;
  border-radius: 5px;
}

/* 2016/11/29 アクセシビリティ対応 <p><span>→<ul><li> display: inline-block;追加 */
div#footertextlink p,
ul#footertextlink li {
  font-size: 0.9em;
  margin: 0 3px;
  padding: 3px 10px;
}

ul#footertextlink li.cc {
  border-radius: 3px;
  float: left;
  font-size: 0.9em;
  margin: 0 5px;
  padding: 2px 8px;
}

/* 2016/11/29 アクセシビリティ対応 <p><span>→<ul><li>*/
div#footerBtn p#footerBtn1,
ul#footerBtn li#footerBtn1 {
  /*background-image:url("../../images/top1/icon_footer01.gif");
    background-repeat:no-repeat;
    background-position:10px center;*/
}
/* 2016/11/29 アクセシビリティ対応 <p><span>→<ul><li>*/
div#footerBtn p#footerBtn1 a,
ul#footerBtn li#footerBtn1 a {
  padding-left: 45px;
}
/* 2016/11/29 アクセシビリティ対応 <p><span>→<ul><li>*/
div#footerBtn p#footerBtn2,
ul#footerBtn li#footerBtn2 {
  /*background-image:url("../../images/top1/icon_footer02.gif");
    background-repeat:no-repeat;
    background-position:10px center;*/
}
/* 2016/11/29 アクセシビリティ対応 <p><span>→<ul><li>*/
div#footerBtn p#footerBtn2 a,
ul#footerBtn li#footerBtn2 a {
  padding-left: 40px;
}

ul#footertextlink li {
  padding: 6px 0px 0px;
}

ul#footertextlink li a {
  text-decoration: none;
}

/* 2016/11/29 アクセシビリティ対応 <p><span>→<ul><li>*/
div#footerBtn p a,
ul#footerBtn li a {
  display: block;
  text-decoration: none;
  padding: 0px 2px 2px 13px;
}

/* 2016/11/29 アクセシビリティ対応 <p><span>→<ul><li>*/
div#footerBtn .logo,
div#footerBtnAndLogo .logo {
  float: right;
  padding: 0px;
  margin: 0px;
}

@media screen and (max-width: 1100px) and (min-width: 768px) {
  /* タブレット用のスタイル記述 */
  #footerArea img {
    max-width: 100%;
    height: auto;
    width/***/: auto;
  }

  #toiawase {
    width: 95%;
  }

  #footertextlink,
  ul#footertextlink {
    width: 95%;
  }

  #footerImg {
    margin: 10px 0px 0px 0px;
  }

  #toiawasenaiyou {
    width: 100%;
    margin: 0px auto;
  }

  #footerImg2 {
    display: none;
  }

  #toiawasetitle {
    padding: 15px 0px 15px;
  }

  #toiawase p {
    line-height: 2.5em;
    border-bottom: 1px dotted #ffffff;
  }

  .footer_Menu_item ul li {
    float: left;
    margin: 0px auto 0px;
    padding: 0px 10px;
    border-right: 1px solid #fff;
  }
}

/* スマホ用 */
@media screen and (max-width: 767px) {
  img {
    max-width: 100%;
    height: auto;
    width/***/: auto;
  }

  #siteinfo {
    width: 100%;
    margin: 0px auto;
  }

  #toiawase {
    width: 100%;
    margin: 0px auto;
  }

  #toiawase p {
    margin: 0px 15px;
    border-bottom: 1px dotted #ffffff;
  }

  #toiawasetitle {
    padding: 15px 0px 15px;
  }

  .toiawase {
    width: calc(100% - 20px);
    padding: 40px 0px 0px 0px;
  }

  .footerimg {
    float: none;
    margin: 10px auto 30px auto;
    text-align: center;
    justify-content: center;
  }

  .add {
    width: unset;
  }

  .add_title {
    font-weight: bold;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 10px 0px;
    font-size: 0.9em;
    margin: 10px auto;
    text-align: center;
  }

  .add p {
    padding: 15px 0px;
    font-size: 0.9em;
  }

  .footer_Menu {
    font-size: 1em;
    width: calc(100% - 80px);
    margin: 15px auto 20px;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0px 5px;
  }

  .footer_Menu_item ul li {
    float: left;
    margin: 0px auto 0px;
    padding: 0px 31px;
    border-right: 0px solid #fff;
  }

  .footer_Menu_item ul li.tate {
    display: none;
  }

  .footer_Menu_item ul li {
    float: unset;
    margin: 0px auto 0px;
    padding: 5px 10px;
  }

  #toiawasenaiyou {
    width: 100%;
    margin: 0px auto;
  }

  div#footertextlink,
  ul#footertextlink {
    float: left;
    margin: 8px 15px;
  }

  #cp {
    text-align: center;
    margin: 0px auto;
    width: 100%;
    border-top: 0px;
  }
}

/* TOPへボタン */
#topbtn {
  position: fixed;
  right: 1rem;
  z-index: 1200;
}
#footer_area {
  background: linear-gradient(#4ea2d9 0%, #2585c5 52.3%, #114e77 100%);
}

#footer_area p {
  color: white;
  letter-spacing: -0.5px;
}
.houjin {
  color: white;
}

.footer_Menu_item ul {
  display: inline-block;
}
.footer_Menu_item a {
  color: white;
  text-decoration: underline;
}
.footer_Menu_item a:hover {
  text-decoration: underline;
  position: relative;
  top: 1px;
  left: 1px;
}

#cp {
  color: #ffffff;
}
